<template>
  <div>
    <actor-detail-o-p-global
      :itemsBreadcrumbs="itemsBreadcrumbs"
      :sBackHref="sBackHref"
      :bWithPathOrNameRoute="true"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemsBreadcrumbs: [],
    };
  },
  beforeMount() {
    this.setNameBreadcrumbs();
  },
  methods: {
    setNameBreadcrumbs() {
      let aBreadcrumbs = [
        {
          text: "Operaciones de caja",
          disabled: false,
          to: { name: "OPPayable" },
        },
        {
          text: "Detalle de una operación de caja",
          link: true,
          exact: true,
          disabled: false,
          to: { name: "OPPayableDetail" },
          params: { id: this.$route.params.idOP },
        },
        {
          text: "Detalle de una empresa",
          link: true,
          exact: true,
          disabled: true,
          to: { name: "" },
          params: { id: this.$route.params.idOP },
        },
      ];
      this.itemsBreadcrumbs = aBreadcrumbs;
      this.sBackHref = `/home/op-payable-detail/${this.$route.params.idOP}`;
    },
  },
};
</script>

<style>
</style>